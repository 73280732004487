jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767 ) {
		$(".main-content-wrap").fitVids({ ignore: "nofit" });
		$(".homebox").fitVids({ ignore: "nofit" });
	}

	/* Accessible menu */
	$(".main-nav-dd-widget:has('.container-wrapper')").addClass("has-dropdown");

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".main-nav-dd-widget").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-dd-widget").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();


	/* Webflow */
	// Sticky header
	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 150);
	});

	// Make the Back To Top slide smoothly
	$("#topLink").click(function() {
		$('html,body').animate({
			scrollTop: $("#Top").offset().top},
		800
		);
	});


	Webflow.require('ix').init([
		{slug: "nav-accent-reveal",name: "Nav Accent Reveal",value: {style: {},triggers: []}},
		{slug: "dropdown-smooth-open",name: "Dropdown Smooth Open",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 350ms ease 0, height 350ms ease 0"}],stepsB: [{height: "0px",transition: "opacity 100ms ease 0ms, height 100ms ease 0ms"}]}]}},
		{slug: "mobile-search-reveal",name: "Mobile Search Reveal",value: {style: {},triggers: [{type: "click",selector: ".header-mobile-search-reveal",stepsA: [{height: "0px"},{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"}]}]}},
		{slug: "slide-learn-more",name: "Slide Learn More",value: {style: {opacity: 0},triggers: [{type: "slider",stepsA: [{wait: "1100ms"},{opacity: 1,transition: "opacity 500ms ease-out-quad 0"}],stepsB: [{wait: "500ms"},{opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dropdown-list",descend: true,stepsA: [{wait: "100ms"},{display: "block",opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{wait: "100ms"},{display: "none",opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{slug: "cb-title-hover",name: "CB Title Hover",value: {style: {},triggers: [{type: "hover",selector: ".cb-title-underline",descend: true,stepsA: [{opacity: 1,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 0,transition: "opacity 250ms ease 0"}]}]}},
		{slug: "slidecaptionheader",name: "SlideCaptionHeader",value: {style: {opacity: 0,x: "-300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "500ms"},{opacity: 0,transition: "opacity 200 ease 0, transform 200 ease 0",x: "-300px",y: "0px",z: "0px"}]}]}},
		{slug: "slidecaptionparagraph",name: "SlideCaptionParagraph",value: {style: {opacity: 0,x: "-300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "350ms"},{opacity: 1,transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "500ms"},{opacity: 0,transition: "opacity 200 ease 0, transform 200 ease 0",x: "-300px",y: "0px",z: "0px"}]}]}},
		{slug: "slidecaptionbutton",name: "SlideCaptionButton",value: {style: {opacity: 0,x: "-300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "450ms"},{opacity: 1,transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "500ms"},{opacity: 0,transition: "opacity 200 ease 0, transform 200 ease 0",x: "-300px",y: "0px",z: "0px"}]}]}},
		{slug: "cb-load",name: "CB Load",value: {style: {opacity: 0,x: "0px",y: "150px",z: "0px"},triggers: [{type: "scroll",offsetTop: "0%",offsetBot: "0%",stepsA: [{opacity: 1,transition: "transform 400ms ease-out-quad 0, opacity 650ms ease-out-quad 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb-load-2",name: "CB Load 2",value: {style: {opacity: 0,x: "0px",y: "150px",z: "0px"},triggers: [{type: "scroll",offsetTop: "0%",offsetBot: "0%",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 400ms ease-out-quad 0, opacity 650ms ease-out-quad 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb-load-3",name: "CB Load 3",value: {style: {opacity: 0,x: "0px",y: "150px",z: "0px"},triggers: [{type: "scroll",offsetTop: "0%",offsetBot: "0%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 400ms ease-out-quad 0, opacity 650ms ease-out-quad 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb-hover-lift",name: "CB Hover Lift",value: {style: {},triggers: [{type: "hover",stepsA: [{transition: "transform 300ms ease 0",x: "0px",y: "-12px",z: "0px"}],stepsB: [{transition: "transform 300ms ease 0",x: "0px",y: "0px",z: "0px"}]}]}},
		{slug: "cb-row-scroll-load",name: "CB Row Scroll Load",value: {style: {opacity: 0,x: "0px",y: "200px",z: "0px"},triggers: [{type: "scroll",offsetBot: "15%",stepsA: [{opacity: 1,transition: "opacity 1000ms ease-out-quad 0, transform 500ms ease-out-quad 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "hide-back-to-top-button",name: "Hide Back to Top Button",value: {style: {},triggers: [{type: "scroll",selector: ".back-to-top",stepsA: [{wait: "1s",display: "block",opacity: 0,transition: "opacity 1s ease 0"}],stepsB: [{wait: "1s",display: "block",opacity: 1,transition: "opacity 450ms ease 0"}]}]}},
		{slug: "hidden-on-load",name: "Hidden on Load",value: {style: {display: "block",opacity: 0},triggers: []}}
	]);
});

! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
